import { defineStore } from 'pinia'
import type {
  Beneficiary,
  BillChartDataType,
  BillSummaryType,
  CreditType,
  OCRType,
  Invoice,
} from '@/shared/interfaces'
import type { GetAccountingDetailsForVendorFromBillResponse } from '~/types/apiResponse/accounting.response'

interface DefaultStateType {
  allBills: {
    result: Invoice[]
    has_more: boolean
    has_previous_page: boolean
    page_number: number
    total: number
    total_pages: number
  }
  totalBillSummary: {
    summary: BillSummaryType | null
    fetched: boolean
  }
  canUseNewBillFeature: boolean
  balances: CreditType | null
  fetchedAvailableCredit: boolean
  selectedVendor: Beneficiary | null
  vendorPrefill: string | null
  selectedBill: Invoice | null
  selectedBillInvoices: (File | null)[]
  billChartData: {
    data: BillChartDataType[] | null
    fetched: boolean
    updating: boolean
  }
  ocrData: OCRType | null
  fetched: boolean
  loading: boolean
  error: string | null
  showEditBill: boolean
  vendorAccountPrefill:
    | GetAccountingDetailsForVendorFromBillResponse['result']
    | null
}

const defaultState: () => DefaultStateType = () => ({
  allBills: {
    result: [],
    has_more: false,
    has_previous_page: false,
    page_number: 0,
    total: 0,
    total_pages: 0,
  },
  canUseNewBillFeature: false,
  totalBillSummary: {
    summary: null,
    fetched: false,
  },
  billChartData: {
    data: null,
    fetched: false,
    updating: false,
  },
  vendorPrefill: null,
  ocrData: null,
  balances: null,
  fetchedAvailableCredit: false,
  selectedVendor: null,
  selectedBill: null,
  selectedBillInvoices: [],
  fetched: false,
  loading: false,
  error: null,
  showEditBill: false,
  vendorAccountPrefill: null,
})

export const useBillsStore = defineStore('bills', {
  state: defaultState,
  actions: {
    setShowEditBill(val: boolean) {
      this.showEditBill = val
    },
    setVendorPrefill(val: string | null) {
      this.vendorPrefill = val
    },
    setOCRData(val: OCRType | null) {
      this.ocrData = val
    },
    setSelectedVendor(vendor: Beneficiary | null) {
      this.selectedVendor = vendor
    },
    setSelectedBillInvoices(files: (File | null)[]) {
      this.selectedBillInvoices = files
    },
    setSelectedBill(bill: Invoice | null) {
      this.selectedBill = bill
    },
    async getAllBills(sellerId: string, payload: any) {
      try {
        this.loading = true
        const { $lenkieBankingApi } = useNuxtApp()

        if (!sellerId) return

        let uri = `/Bills?`
        let searchParams = {
          OrganisationId: sellerId,
        }

        if (payload) {
          searchParams = {
            ...searchParams,
            ...payload,
          }
        }
        uri += new URLSearchParams(searchParams)

        const { data } = await $lenkieBankingApi.get(uri)
        this.fetched = true
        this.allBills = data.result
        this.allBills.result = data.result.data
      } catch (error) {
        this.error = 'Failed to fetch vendors'
      } finally {
        this.loading = false
      }
    },
    async getTotalBillsSummary(sellerId: string) {
      try {
        this.totalBillSummary.fetched = false
        const { $lenkieBankingApi } = useNuxtApp()

        if (!sellerId) return

        const { data } = await $lenkieBankingApi.get(
          `/Bills/Summary/${sellerId}`,
        )
        this.totalBillSummary.summary = data
        this.totalBillSummary.fetched = true
      } catch (errors) {
        this.totalBillSummary.fetched = false
      }
    },
    async getBillPayBalances(sellerId: string) {
      try {
        this.fetchedAvailableCredit = false
        const { $lenkieBankingApi } = useNuxtApp()

        if (!sellerId) return

        const { data } = await $lenkieBankingApi.get(`/Balances/${sellerId}`)
        this.balances = data.credit
        this.fetchedAvailableCredit = true
      } catch (errors) {
        this.fetchedAvailableCredit = false
        // this.$bugsnag.notify(`

        //   userId: ${rootState.profile.data?.sellerId},
        //   'username: ${rootState.profile.data?.name},
        //   'company name: ${rootState.profile.data?.organisation?.registered_name},
        //   functionName: 'getBillPayBalances',

        //   axiosResponse: {
        //     status: ${JSON.stringify(errors?.response?.status)},
        //     data: ${JSON.stringify(errors?.response?.data)},
        //     headers: ${JSON.stringify(errors?.response?.headers)},
        //   },
        //   axiosRequest: {
        //     method: 'GET',
        //     url: ${`/Beneficiaries/${rootState.profile.data?.sellerId}`},
        //   },
        // `)
      }
    },
    async getBillChartData(sellerId: string, from?: string, to?: string) {
      this.billChartData.updating = true
      try {
        const { $lenkieBankingApi } = useNuxtApp()

        let uri = '/Bills/payment-summary-group-by-date?'

        const params = {
          orgId: sellerId,
          ...(from && { from }),
          ...(to && { to }),
        }

        uri += new URLSearchParams(params)
        const { data } = await $lenkieBankingApi.get(uri)
        this.billChartData.data = data
        this.billChartData.fetched = true
        this.billChartData.updating = false
      } catch (errors) {
        this.billChartData.fetched = false
        this.billChartData.updating = false
      }
    },
  },
})
